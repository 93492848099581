/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
// import { Container, Row } from "reactstrap";

// core components
import MainNavbar from "components/Navbars/MainNavbar.jsx";
import CardsFooter from "components/Footers/CardsFooter.jsx";
import CookieAlert from "components/CookieAlert/CookieAlert.jsx";

import DatenschutzHeader from "./DatenschutzSections/DatenschutzHeader";
import DatenschutzContent from "./DatenschutzSections/DatenschutzContent";

// index page sections

class Index extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  render() {
    return (
      <>
        <CookieAlert />
        <MainNavbar />
        <main ref="main">
          <DatenschutzHeader />
          <DatenschutzContent />
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Index;
