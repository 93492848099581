import React from "react";

import { Container, Row, Col, Badge, Button } from "reactstrap";

class TextAndImage extends React.Component {
  render() {
    return (
      <>
        <Container>
          <Row className="row-grid align-items-center">
            <Col className="order-md-2" md="6">
              <img
                alt={this.props.imgAlt}
                className="img-fluid floating"
                src={this.props.img}
              />
            </Col>
            <Col className="order-md-1" md="6">
              <div className="pr-md-5">
                <h3>{this.props.header}</h3>
                <p>
                  {this.props.text}
                </p>
                <Button
                  className="btn-icon mb-0"
                  color={this.props.btnColor}
                  href={this.props.btnHref}
                >
                  <span className="btn-inner--text">{this.props.btnText}</span>
                </Button>
                <ul className="list-unstyled mt-3">
                  {
                    this.props.badges.map((badge) =>
                      <li className="py-2" key={badge.key}>
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="primary"
                            >
                              <i className={badge.icon} />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              {badge.text}
                            </h6>
                          </div>
                        </div>
                      </li>
                    )
                  }
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default TextAndImage;