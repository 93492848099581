/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

import Nutzerbewertung from "./Nutzerbewertung";

class Bewertungen extends React.Component {
  state = {
    userReviews: [
      {
        "id": 1,
        "product_id": 1,
        "product_title": "RFID & NFC Kreditkarten-Schutzh\u00fcllen",
        "first_name": "Tina",
        "last_name": null,
        "text": "Die Kartenh\u00fcllen sehen optisch sehr sch\u00f6n aus. Ein scharfes Fotodesign ohne Pixel! Sie passen super in mein Portemonnaie und halten was sie versprechen. Kein kontaktloses Zahlen durch die H\u00fclle.",
        "stars": 5
      },
      {
        "id": 2,
        "product_id": 2,
        "product_title": "RFID & NFC Kreditkarten- und Reisepass Schutzh\u00fcllen",
        "first_name": "Jasmin",
        "last_name": "Becker",
        "text": "Die Designs sind eindrucksvoll und \u00fcberzeugen mich sehr.\r\nSignale werden zuverl\u00e4ssig blockiert.\r\nKann ich nur weiter empfehlen!",
        "stars": 5
      },
      {
        "id": 3,
        "product_id": 1,
        "product_title": "RFID & NFC Kreditkarten-Schutzh\u00fcllen",
        "first_name": "Lothar",
        "last_name": "Held",
        "text": "Artikel wie beschrieben, kamen P\u00fcnktlich,\r\nsch\u00fctzen die EC Karte, habe ich ausprobiert und bin sehr zufrieden.\r\nWer seine Karten sch\u00fctzen m\u00f6chte, sollte sich so eine Schutzh\u00fclle zulegen",
        "stars": 5
      }
    ],
  }

  render() {
    if (this.state.userReviews != null && this.state.userReviews.length > 0) {
      return (
        <>
          <section className="section bg-gradient-primary">
            <Container className="py-md">
              <Row className="align-items-center justify-content-center text-white">
                <Col lg="6">
                  <h1 className="text-white font-weight-light">
                    Das sagen<br />unsere Kunden
                </h1>
                </Col>
                {
                  this.state.userReviews.map((userReview, i) => {
                    // set last element full size if its alone on its row (odd array numbers)
                    let size = 6;
                    if (i === (this.state.userReviews.length) - 1 && i % 2 === 1) {
                      size = 12;
                    }
                    if (userReview.first_name === null) {
                      userReview.first_name = "";
                    }
                    if (userReview.last_name === null) {
                      userReview.last_name = "";
                    }
                    if (userReview.text === null) {
                      userReview.text = "";
                    }
                    // Return the element. Also pass key     
                    return (
                      <Col lg={size} key={"userReview-" + userReview.id}>
                        <Nutzerbewertung
                          name={userReview.first_name + " " + userReview.last_name}
                          text={userReview.text}
                          stars={userReview.stars}
                          productname={userReview.product_title}
                          productlink={"/produkte?id=" + userReview.product_id} />
                      </Col>
                    )
                  })
                }
              </Row>
            </Container>
          </section>
        </>
      );
    } else {
      return null;
    }
  }
}

export default Bewertungen;
