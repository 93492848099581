/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { Badge } from "reactstrap";

class Nutzerbewertung extends React.Component {

  createStars = () => {
    let stars = []
    // loop to create stars
    for (let j = 0; j < this.props.stars && j < 5; j++) {
      stars.push(<i className="fas fa-star" key={j}/>)
    }
    if(stars.length < 5) {
      for (let i = stars.length; i < 5; i++) {
        stars.push(<i className="far fa-star" key={i}/>)
      }
    }
    return stars
  }

  render() {
    return (
      <>
        <div>
          <p className="lead">{this.props.name}</p>
        </div>
        <div>{this.props.text}</div>
        <div className="mt-3">{this.createStars()}
        </div>
        <div>
          <Link
            to={this.props.productlink}
          >
            <Badge color="secondary">{this.props.productname}</Badge>
          </Link>
        </div>
      </>
    );
  }
}

export default Nutzerbewertung;
