/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss";

import Index from "views/Index.jsx";
import Kontakt from "views/Kontakt.jsx";
import Impressum from "views/Impressum.jsx";
import Datenschutz from "views/Datenschutz.jsx";
import TuevZertifikat from "views/TuevZertifikat.jsx";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route
        path={["/", "/rfid", "/anfrage-gesendet"]}
        exact
        render={(props) => <Index {...props} />}
      />
      <Route path="/kontakt" exact render={(props) => <Kontakt {...props} />} />
      {/* <Route
        path="/produkte"
        exact
        render={props => <Produkte {...props} />}
      /> */}
      {/* <Route
        path="/individuelle-produkte"
        exact
        render={props => <Geschaeftskunden {...props} />}
      /> */}
      <Route
        path="/impressum"
        exact
        render={(props) => <Impressum {...props} />}
      />
      <Route
        path="/datenschutz"
        exact
        render={(props) => <Datenschutz {...props} />}
      />
      <Route
        path="/tuev-zertifikat"
        exact
        render={(props) => <TuevZertifikat {...props} />}
      />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
