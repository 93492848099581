import React from "react";
import { withRouter } from "react-router";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Button,
  FormText,
  Collapse,
  Alert
} from "reactstrap";
// nodejs library that concatenates classes
import classnames from "classnames";
import { Link } from "react-router-dom";
// import RfidSvgSleeve from "./RfidSvgSleeve";

class RFIDSleevesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company: '',
      salutation: '',
      title: '',
      firstname: '',
      lastname: '',
      email: '',
      message: '',
      quantity: '',
      sampleCheck: false,
      sampleStreet: '',
      samplePostcode: '',
      sampleCity: '',
      datenschutzCheck: false,
      formStatus: ''
    };
    this.toggleSample = this.toggleSample.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.restartForm = this.restartForm.bind(this);
  }

  toggleSample() {
    this.setState({ collapseSample: !this.state.collapseSample });
  }

  handleChange = (event) => {
    event.persist();
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  restartForm(event) {
    this.setState({
      company: '',
      salutation: '',
      title: '',
      firstname: '',
      lastname: '',
      email: '',
      message: '',
      quantity: '',
      sampleCheck: false,
      sampleStreet: '',
      samplePostcode: '',
      sampleCity: '',
      datenschutzCheck: false,
      formStatus: ''
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({
      formStatus: "loading",
    });

    const formData = {
      company: this.state.company,
      salutation: this.state.salutation,
      title: this.state.title,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      email: this.state.email,
      message: this.state.message,
      quantity: this.state.quantity,
      sampleCheck: this.state.sampleCheck,
      sampleStreet: this.state.sampleStreet,
      samplePostcode: this.state.samplePostcode,
      sampleCity: this.state.sampleCity,
      datenschutzCheck: this.state.datenschutzCheck,
    }

    try {
      fetch(process.env.REACT_APP_API_BASE + '/forms/postContactForm.php', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData)
      }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          // show success message
          this.setState({
            formStatus: "success",
          }, () => {
            console.log("before push")
            this.props.history.push(`/anfrage-gesendet`)
        });
        } else {
          this.setState({
            formStatus: "error",
          });
        }
      });
    } catch (error) {
      this.setState({
        formStatus: "error",
      });
    }

  }

  render() {
    return (
      <>
        <Container id="kontakt">
          <Row className="justify-content-center mt--300">
            <Col lg="8">
              <Card className="bg-gradient-secondary shadow">
                <CardBody className="p-lg-5">
                  <Form onSubmit={this.handleSubmit}>
                    {/**
                     * Kontaktdaten
                    */}
                    <h4 className="mb-3">Kontaktaufnahme</h4>
                    <Collapse isOpen={this.state.formStatus !== "success"}>
                      <FormGroup
                        className={classnames({
                          focused: this.state.companyFocused,
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-badge" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Firma"
                            type="text"
                            name="company"
                            id="companyInput"
                            value={this.state.company}
                            onFocus={(e) =>
                              this.setState({ companyFocused: true })
                            }
                            onBlur={(e) =>
                              this.setState({ companyFocused: false })
                            }
                            onChange={(e) => this.handleChange(e)}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: this.state.titleFocused,
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-single-02" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Anrede*"
                            type="text"
                            name="salutation"
                            value={this.state.salutation}
                            onFocus={(e) => this.setState({ titleFocused: true })}
                            onBlur={(e) => this.setState({ titleFocused: false })}
                            onChange={(e) => this.handleChange(e)}
                            required
                            list="salutations"
                          />
                          <datalist id="salutations">
                            <option value="Herr" />
                            <option value="Frau" />
                          </datalist>
                          <Input
                            placeholder="Titel"
                            type="text"
                            name="title"
                            value={this.state.title}
                            onFocus={(e) => this.setState({ titleFocused: true })}
                            onBlur={(e) => this.setState({ titleFocused: false })}
                            onChange={(e) => this.handleChange(e)}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: this.state.nameFocused,
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-single-02" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Vorname*"
                            type="text"
                            name="firstname"
                            value={this.state.firstname}
                            onFocus={(e) => this.setState({ nameFocused: true })}
                            onBlur={(e) => this.setState({ nameFocused: false })}
                            onChange={(e) => this.handleChange(e)}
                            required
                          />
                          <Input
                            placeholder="Nachname*"
                            type="text"
                            name="lastname"
                            value={this.state.lastname}
                            onFocus={(e) => this.setState({ nameFocused: true })}
                            onBlur={(e) => this.setState({ nameFocused: false })}
                            onChange={(e) => this.handleChange(e)}
                            required
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: this.state.emailFocused,
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="E-Mail*"
                            type="email"
                            name="email"
                            value={this.state.email}
                            onFocus={(e) => this.setState({ emailFocused: true })}
                            onBlur={(e) => this.setState({ emailFocused: false })}
                            onChange={(e) => this.handleChange(e)}
                            required
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <Input
                          className="form-control-alternative"
                          cols="80"
                          name="message"
                          value={this.state.message}
                          placeholder="Ihre Nachricht"
                          rows="4"
                          type="textarea"
                          onChange={(e) => this.handleChange(e)}
                        />
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: this.state.quantityFocused,
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-ungroup" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Stückzahl"
                            type="number"
                            min="500"
                            step="1"
                            name="quantity"
                            value={this.state.quantity}
                            onFocus={(e) =>
                              this.setState({ quantityFocused: true })
                            }
                            onBlur={(e) =>
                              this.setState({ quantityFocused: false })
                            }
                            onChange={(e) => this.handleChange(e)}
                          />
                        </InputGroup>
                        <FormText color="muted">
                          Mindeststückzahl: 500 |{" "}
                          <a href="#rfid-preisliste">Preisliste</a>
                        </FormText>
                      </FormGroup>
                      {/* <hr />
                    <FormGroup className={classnames({
                      focused: this.state.designFocused,
                    })}>
                      <Button className="w-100 d-flex justify-content-between align-items-center" color="dark" onClick={this.toggleDesign} style={{ marginBottom: '1rem' }}>
                        {designButtonContent}
                      </Button>
                      <Collapse isOpen={this.state.collapseDesign}>
                        <FormGroup>
                          <Input type="file" name="file" id="customerDesignFile" onChange={this.handleChange} accept="image/png, image/jpeg" />
                          <img
                            src={this.state.file}
                            className="img-fluid my-3"
                          />
                        </FormGroup>
                      </Collapse>
                    </FormGroup> */}
                      <FormGroup>
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            className="custom-control-input"
                            id="sampleCheck"
                            name="sampleCheck"
                            type="checkbox"
                            checked={this.state.sampleCheck}
                            onChange={(e) => this.handleChange(e)}
                          />
                          <label className="custom-control-label" htmlFor="sampleCheck">
                            <b>Bitte Senden sie mir ein kostenloses Sample zu.</b>
                          </label>
                        </div>
                        <Collapse isOpen={this.state.sampleCheck}>
                          <FormGroup
                            className={classnames({
                              focused: this.state.sampleStreetFocused,
                            })}
                          >
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-building" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Straße und Hausnummer"
                                type="text"
                                name="sampleStreet"
                                value={this.state.sampleStreet}
                                onFocus={(e) => this.setState({ sampleStreetFocused: true })}
                                onBlur={(e) => this.setState({ sampleStreetFocused: false })}
                                onChange={(e) => this.handleChange(e)}
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup
                            className={classnames({
                              focused: this.state.sampleCityFocused,
                            })}
                          >
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-square-pin" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="PLZ"
                                type="number"
                                max="99999"
                                name="samplePostcode"
                                value={this.state.samplePostcode}
                                onFocus={(e) => this.setState({ sampleCityFocused: true })}
                                onBlur={(e) => this.setState({ sampleCityFocused: false })}
                                onChange={(e) => this.handleChange(e)}
                              />
                              <Input
                                placeholder="Ort"
                                type="text"
                                name="sampleCity"
                                value={this.state.sampleCity}
                                onFocus={(e) => this.setState({ sampleCityFocused: true })}
                                onBlur={(e) => this.setState({ sampleCityFocused: false })}
                                onChange={(e) => this.handleChange(e)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Collapse>
                      </FormGroup>
                      <FormGroup className="mb-4">
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            id="datenschutzCheck"
                            name="datenschutzCheck"
                            checked={this.state.datenschutzCheck}
                            type="checkbox"
                            required
                            onChange={(e) => this.handleChange(e)}
                          />
                          <label className="custom-control-label" htmlFor="datenschutzCheck">
                            Ich habe die{" "}
                            <Link to="/datenschutz">Datenschutzerklärung</Link>{" "}
                            gelesen und akzeptiere diese.*
                          </label>
                        </div>
                      </FormGroup>
                      <Collapse isOpen={this.state.formStatus === "error"}>
                        <Alert color="danger">
                          <span className="alert-inner--icon">
                            <i className="ni ni-bell-55" />
                          </span>{" "}
                          <span className="alert-inner--text">
                            <strong>Fehler!</strong><br />Leider ist beim absenden der Anfrage etwas schief gelaufen.<br />Bitte probieren Sie es erneut.
                          </span>
                        </Alert>
                      </Collapse>
                      <div>
                        <Button
                          block
                          className="btn-round"
                          color="primary"
                          size="lg"
                          type="submit"
                          value="Submit"
                        >
                          <Collapse isOpen={this.state.formStatus !== "loading"}>
                            Jetzt Anfragen
                          </Collapse>
                          <Collapse isOpen={this.state.formStatus === "loading"}>
                            <i className="fas fa-spinner fa-pulse"></i>
                          </Collapse>
                        </Button>
                      </div>
                      <div>
                        <p className="small muted mt-3 mb-0">
                          Mit * markierte Felder sind Pflichtfelder.
                        </p>
                      </div>
                    </Collapse>
                    <Collapse isOpen={this.state.formStatus === "success"}>
                      <Alert color="primary">
                        <span className="alert-inner--icon">
                          <i className="ni ni-check-bold" />
                        </span>{" "}
                        <span className="alert-inner--text">
                          <strong>Ihre Anfrage wurde erfolgreich abgesendet.</strong><br />
                          Wir melden uns in kürze bei Ihnen.
                        </span>
                      </Alert>
                      <Button
                        block
                        className="button-round"
                        color="dark"
                        size="lg"
                        onClick={this.restartForm}
                      >
                        Neue Anfrage
                      </Button>
                    </Collapse>
                  </Form>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(RFIDSleevesForm);
