/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
// import { Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

// core components
import MainNavbar from "components/Navbars/MainNavbar.jsx";
import CardsFooter from "components/Footers/CardsFooter.jsx";
import CookieAlert from "components/CookieAlert/CookieAlert.jsx";
import Hero from "components/Heros/Hero.jsx";
import SVGSeparator from "components/Separators/SVGSeparator";
import TextAndImage from "components/General/TextAndImage";
import RFIDSleevesForm from "components/Forms/RFIDSleevesForm";

// index page sections
import RFIDPricing from "./IndexSections/RFIDPricing.jsx";
import RfidErklaerung from "./IndexSections/RfidErklaerung.jsx";
import Bewertungen from "./IndexSections/Bewertungen.jsx";

class Index extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  render() {
    return (
      <>
        <CookieAlert />
        <MainNavbar />
        <main ref="main">
          {/* shape Hero */}
          <section className="section section-lg section-hero pb-250">
            <Hero />
            <SVGSeparator />
          </section>

          <section className="section">
            <RFIDSleevesForm></RFIDSleevesForm>
          </section>
          <section className="section" id="details">
            <TextAndImage
              img={require("../assets/img/business/rfid_sleeve_geschaeftskunden_400.png")}
              imgAlt="Ihr Design"
              header="Individuelle Produktdesigns"
              text="Starten Sie Ihr Jahr richtig, denn nur wer bei seinen Kunden in guter Erinnerung bleibt, wird auch in Zukunft die Geschäfte abschließen."
              btnColor="primary"
              btnHref="#kontakt"
              btnText="Jetzt Anfragen"
              badges={[
                {
                  key: 1,
                  icon: "ni ni-money-coins",
                  text: (
                    <span>
                      Attraktive Konditionen
                      <br />
                      Ab 0,59€ / Stück{" "}
                      <small className="text-muted">zzgl. USt</small>
                    </span>
                  ),
                },
                {
                  key: 2,
                  icon: "ni ni-palette",
                  text: "Individualisierte Produkte",
                },
                {
                  key: 3,
                  icon: "ni ni-satisfied",
                  text: "Höchste Qualität",
                },
                {
                  key: 4,
                  icon: "fas fa-user-shield",
                  // text: "TÜV geprüft",
                  text: <Link to="/tuev-zertifikat">TÜV geprüft</Link>,
                },
                {
                  key: 5,
                  icon: "fas fa-box-open",
                  // text: "Kostenloses Sample",
                  text: <a href="#kontakt">Kostenloses Sample</a>,
                },
              ]}
            />

            {/* <VerticalBlocksAndImage
              img={require("../assets/img/business/rfid_sleeve_geschaeftskunden_400.png")}
              imgAlt="Ihr Design"
              color1="primary"
              icon1="ni ni-building"
              header1="Überschrift 1"
              text1="Do mollit minim nostrud ex. Voluptate veniam exercitation laboris pariatur ex laborum incididunt voluptate incididunt cillum ex."
              icon2="ni ni-satisfied"
              header2="Überschrift 2"
              text2="Do mollit minim nostrud ex. Voluptate veniam exercitation laboris pariatur ex laborum incididunt voluptate incididunt cillum ex."
              button2Href="#"
              button2Label="Label"
              icon3="ni ni-building"
              header3="Überschrift 3"
              text3="Do mollit minim nostrud ex. Voluptate veniam exercitation laboris pariatur ex laborum incididunt voluptate incididunt cillum ex."
              button3Href="#"
              button3Label="Label"
            /> */}
          </section>
          <section className="section">
            <RFIDPricing></RFIDPricing>
          </section>
          <section>
            <Bewertungen />
          </section>
          <RfidErklaerung></RfidErklaerung>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Index;
