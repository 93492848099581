import React from "react";

import { Container, Row, Col, Button } from "reactstrap";

class Hero extends React.Component {
  render() {
    return (
      <>
        <Container className="py-lg-md d-flex">
          <div className="col px-0">
            <Row>
              <Col lg="6">
                <h1 className="display-3 text-white">
                  Individuelle RFID Schutzhüllen
                </h1>
                <p className="lead text-white">
                  Sie wollen Ihren Kunden in Erinnerung bleiben und nicht mehr
                  nur öde Visitenkarten oder Kugelschreiber verteilen, die
                  sowieso in den Müll wandern? Kontaktieren Sie uns jetzt für ein
                  kostenloses Sample.
                </p>
                <div className="btn-wrapper">
                  <Button
                    className="btn-icon mb-3 mb-sm-0 mr-3"
                    color="primary"
                    href="#kontakt"
                  >
                    <span className="btn-inner--text">Jetzt Anfragen</span>
                  </Button>
                  <Button
                    className="btn-icon mb-3 mb-sm-0"
                    color="secondary"
                    href="#details"
                  >
                    {/* <span className="btn-inner--icon mr-1">
                      <i className="fa fa-code" />
                    </span> */}
                    <span className="btn-inner--text">Mehr Erfahren</span>
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </>
    );
  }
}

export default Hero;