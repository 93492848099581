/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import Video from "react-responsive-video";

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";
import video from "../../assets/video/rfid_sleeves.mp4";

class RfidErklaerung extends React.Component {
  render() {
    return (
      <>
        <section className="section">
          <Container className="py-md">
            <Row className="justify-content-left align-items-center">
              <Col className="mb-5 mb-lg-0" lg="12">
                <h3 className="font-weight-light mb-3 text-center">Was ist RFID & NFC?</h3>
                <p>
                  Die sogenannte RFID-Technologie
                  (radio-frequency-identification) ermöglicht berührungslose
                  Datenübertragung von einem Transponder, beispielsweise einer
                  Girokarte, mit einem Lesegerät (13,56 MHz). NFC
                  (Near-Field-Communication) ist ein Sonderfall, bei welchem der
                  Transponder ebenfalls ein aktives Gerät ist. Dies kommt zum
                  Beispiel bei dem kontaklosen bezahlen mit dem Smartphone am
                  Kartenlesegerät zum Einsatz.
                </p>
                <p className="text-center">
                  <b>
                    Neue Technologien bringen neue Risiken.
                    <br />
                    Haben Sie eine Kreditkarte mit einer kontaktlos
                    Bezahlfunktion?
                  </b>
                </p>
                <p>
                  Nun gibt es vermehrt kriminelle, die genau diese Schnittstelle
                  ausnutzen, um Ihr Geld oder das Ihrer Kunden zu stehlen. Sie
                  lauern immer da, wo viele Menschen sind z.B. an Flughäfen,
                  Einkaufszentren oder sogar am Uni Campus.
                  <br />
                  Schütze Sie sich und Ihre Kunden jetzt vor Kontoleerungen,
                  Identitätsdiebstahl und Kreditkartenfälschungen mit unseren
                  RFID Kreditkartenhüllen. Das extra dünne & robuste Format
                  passt perfekt in jedes kleine Portemonnaie und schützt rund um
                  die Uhr egal wo Sie sind und wer Ihre Daten stehlen will!
                </p>
                <Video mp4={video} controls="controls" /> 
                <br />
                <Button
                  className="btn-icon mb-3 mb-sm-0"
                  color="primary"
                  target="_blank"
                  href="#kontakt"
                >
                  <span className="btn-inner--text">Jetzt Anfragen</span>
                </Button>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default RfidErklaerung;
