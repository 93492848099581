/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Row, Col, Card, Button, CardTitle, CardText } from "reactstrap";

class KontaktCards extends React.Component {
  state = {
    mail: "",
    textTel: "",
    textMail: ""
  }

  componentDidMount() {
    this.SetBusinessMail(this.props.type);
  }

  SetBusinessMail(type) {
    let mail = "";
    let textTel = "";
    let textMail = "";
    if(!type) {
      mail = "info";
      textTel = "Du erreichst";
      textMail = "Schreibe uns und wir beantworten Dein";
    } else {
      mail = type;
      textTel = "Sie erreichen";
      textMail = "Schreiben Sie uns und wir beantworten Ihr";
    }

    this.setState({
      mail: mail,
      textTel: textTel,
      textMail: textMail
     });
  }

  render() {
    return (
      <>
        <Row>
          <Col md="6" className="mb-4">
            <Card body className="shadow">
              <CardTitle className="card-icon-hero">
                <i className="fas fa-mobile"></i>
              </CardTitle>
              <CardText>
              {this.state.textTel} uns telefonisch unter folgender Nummer:<br />
                +49 (0) 176/41100188
                  </CardText>
              <Button color="primary" href="tel:+4917641100188">Jetzt Anrufen</Button>
            </Card>
          </Col>
          <Col md="6" className="mb-4">
            <Card body className="shadow">
              <CardTitle className="card-icon-hero">
                <i className="fas fa-envelope-open-text"></i>
              </CardTitle>
              <CardText>
                {this.state.textMail} Anliegen schnellstmöglich.
              </CardText>
              <Button color="primary" href={"mailto:" + this.state.mail + "@peoplesbest.de"}>E-Mail Schreiben</Button>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default KontaktCards;
