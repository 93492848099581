/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// import axios from 'axios';
// import ProduktCard from "./ProduktCard";


class TuevZertifikatContent extends React.Component {

  render() {
    return (
      <>
        <section className="section pt-100">
          <Container>
            <img className="img-fluid" src="data/tuev-zertifikat/tuev-zertifikat.jpg" alt="TÜV zertifikat" />
          </Container>
        </section>
      </>
    );

  }
}

export default TuevZertifikatContent;
