/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import { Link } from "react-router-dom";
import { Container } from "reactstrap";

import CookieConsent from "react-cookie-consent";

class CookieAlert extends React.Component {
  render() {
    return (
      <>
        <Container>

          <CookieConsent
            location="bottom"
            buttonText="Verstanden"
            cookieName="userCookieAlert"
            style={{ background: "#1A1A1A" }}
            buttonStyle={{ background: "#71C837", fontSize: "13px" }}
            expires={30}
          >
            Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu.{" "}
            <span style={{ fontSize: "10px" }}>
              Weitere Informationen zu Cookies erhalten Sie in unserer <Link to="/datenschutz">Datenschutzerklärung</Link>.
          </span>
          </CookieConsent>
        </Container>
      </>
    );
  }
}

export default CookieAlert;
