/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

class CardsFooter extends React.Component {
  render() {
    return (
      <>
        <footer className="footer has-cards">
          <Container>
            <Row className="row-grid align-items-center my-md">
              <Col lg="6">
                <h3 className="text-primary font-weight-light mb-2">
                  Fragen & Ideen?
                </h3>
                <h4 className="mb-0 font-weight-light">
                  Wir freuen uns auf Deine Nachricht!
                </h4>
              </Col>
              <Col className="text-lg-right btn-wrapper" lg="6">
                <Button
                  className="btn-neutral btn-icon-only btn-round"
                  color="amazon"
                  href="https://www.amazon.de/s?me=A1JMSA8VK2UFNT&marketplaceID=A1PA6795UKMFR9"
                  id="tooltip475038076"
                  size="lg"
                  target="_blank"
                >
                  <i className="fab fa-amazon" />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip475038076">
                  Finde uns auf Amazon
                </UncontrolledTooltip>
                <Button
                  className="btn-neutral btn-icon-only btn-round"
                  color="facebook"
                  href="https://www.facebook.com/peoplesbest.fb/"
                  id="tooltip_footer_fb_icon"
                  size="lg"
                  target="_blank"
                >
                  <i className="fab fa-facebook-f" />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip_footer_fb_icon">
                  Folge uns auf Instagram
                </UncontrolledTooltip>
                <Button
                  className="btn-neutral btn-icon-only btn-round"
                  color="instagram"
                  href="https://www.instagram.com/peoplesbest.insta/"
                  id="tooltip_footer_insta_icon"
                  size="lg"
                  target="_blank"
                >
                  <i className="fab fa-instagram" />
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="tooltip_footer_insta_icon"
                >
                  Folge uns auf Instagram
                </UncontrolledTooltip>
                <Button
                  className="btn-neutral btn-icon-only btn-round"
                  color="primary"
                  href="mailto:info@peoplesbest.de"
                  id="tooltip475038075"
                  size="lg"
                  target="_blank"
                >
                  <i className="fa fa-envelope" />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip475038075">
                  Schreibe uns eine E-Mail
                </UncontrolledTooltip>
              </Col>
            </Row>
            <hr />
            <Row className="align-items-center justify-content-md-between">
              <Col md="6">
                <div className="copyright">
                  © {new Date().getFullYear()}{" "}
                  <a href="https://www.peoplesbest.de">
                    Vitalityballs GbR
                  </a>
                  .
                </div>
              </Col>
              <Col md="6">
                <Nav className="nav-footer justify-content-end">
                  <NavItem>
                    <Link className="nav-link" to="/impressum">
                      Impressum
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link className="nav-link" to="/datenschutz">
                      Datenschutzerklärung
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link className="nav-link" to="/kontakt">
                      Kontakt
                    </Link>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default CardsFooter;
